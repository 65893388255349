import React, {useEffect, useState} from "react";
import global from "../../global";
import {useDispatch, useSelector} from "react-redux";
import {
  Avatar,
  Button, Form,
  ImageUploader,
  ImageUploadItem,
  Input,
  List,
  Result,
  Selector,
  Toast
} from "antd-mobile";
import './ApplyScore.less';
import {ListItem} from "antd-mobile/es/components/list/list-item";

const ApplyScore = () => {
  const applyId = new URLSearchParams(window.location.search).get('applyId');
  // const idCardNumber = new URLSearchParams(window.location.search).get('idCardNumber');
  const dispatch = useDispatch();
  const session = useSelector((state: any) => state.session);
  const applyScore = useSelector((state: any) => state.applyScore);
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (global.getSessionKey() == null) return;
    if (applyId === null) return;

    //读取测评信息
    dispatch.applyScore.getExamApply({applyId}).then((res: any) => {
      if (res.code !== 0) {
        setErr(res.msg);
      }
    });

  }, [applyId, dispatch.applyScore, session.isLogin]);

  return (
    <div className={'ExamQrCode'}>
      {
        err != null && <StatusErrFrame msg={err}/>
      }
      {
        applyScore.info != null && <ExamApplyForm applyId={applyId} setSuccess={setSuccess}/>
      }
      {
        success && <StatusSuccessFrame/>
      }
    </div>
  );
}

const ExamApplyForm = (props: any) => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const options = [
    {label: '优', value: '1'},
    {label: '良', value: '2'},
    {label: '中', value: '3'},
    {label: '差', value: '4'},
  ];
  const judge = ["4"];
  const applyScore = useSelector((state: any) => state.applyScore);
  const [score, setScore] = useState(54);
  const [fileList, setFileList] = useState<ImageUploadItem[]>([]);
  const totalScore = () => {
    let score = getScore(form.getFieldValue('judge0')[0])
      + getScore(form.getFieldValue('judge1')[0])
      + getScore(form.getFieldValue('judge2')[0])
      + getScore(form.getFieldValue('judge3')[0])
      + getScore(form.getFieldValue('judge4')[0])
      + getScore(form.getFieldValue('judge5')[0])
      + getScore(form.getFieldValue('judge6')[0])
      + getScore(form.getFieldValue('judge7')[0])
      + getScore(form.getFieldValue('judge8')[0]);
    // console.log(score);
    setScore(score);
  }
  const getScore = (val: string) => {
    if (val === "1") return 10;//优
    if (val === "2") return 8;//良
    if (val === "3") return 7; //中
    if (val === "4") return 6;//差
    return 0;
  }
  const onFinish = (values: any) => {
    // console.log(values)
    let data = {
      applyId: props.applyId,
      fileKey: values.fileList[0].key,
      memo: values.memo,
      hostingResult: {
        judge0: values.judge0[0],
        judge1: values.judge1[0],
        judge2: values.judge2[0],
        judge3: values.judge3[0],
        judge4: values.judge4[0],
        judge5: values.judge5[0],
        judge6: values.judge6[0],
        judge7: values.judge7[0],
        judge8: values.judge8[0],
        passed: values.passed,
        unPassed: values.unPassed,
        score: score,
      }
    }
    dispatch.applyScore.add(data).then((res:any) => {
      if (res.code === 0) {//成功
        dispatch.applyScore.setData({info: null})//清掉数据
        props.setSuccess(true)
      } else {
        Toast.show({
          icon: 'fail',
          content: res.msg,
        });
        return;
      }
    });
  }

  return (
    <>
      <List header={"考生信息"}>
        <ListItem extra={applyScore.info.idCardName}>
          考生姓名
        </ListItem>
        <ListItem extra={applyScore.info.idCardNumber}>
          身份证号码
        </ListItem>
        <ListItem extra={applyScore.info.specialityName}>
          报考专业
        </ListItem>
        <ListItem extra={applyScore.info.level}>
          报考等级
        </ListItem>
        <ListItem extra={<Avatar src={global.getFileOpenUrl(applyScore.info.photo)} style={{'--size': '128px', padding: 5}}/> }>
          照片
        </ListItem>
      </List>

      <Form
        form={form}
        onFinish={onFinish}
        footer={
          <Button block type='submit' color='primary' size='large'>
            提交
          </Button>
        }
      >
        <Form.Header>打分表</Form.Header>
        <Form.Item name='judge0' label='状态积极表演欲强' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge1' label='声音洪亮气息顺畅' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge2' label='吐字清晰字音准确' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge3' label='节奏起伏生动分明' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge4' label='神态举止自然恰当' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge5' label='理解准确情感饱满:' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge6' label='背诵熟练讲述完整' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge7' label='语法规范词句得当' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='judge8' label='不同语体表达准确' initialValue={judge} onClick={totalScore}>
          <Selector
            columns={options.length}
            options={options}
          />
        </Form.Item>
        <Form.Item name='passed' label='意见和建议'>
          <Input placeholder='请输入意见和建议'/>
        </Form.Item>
        <Form.Item name='unPassed' label='不通过意见及建议' hidden={true}>
          <Input placeholder='请输入不通过意见及建议'/>
        </Form.Item>
        <Form.Item label={'得分: ' + score}>
        </Form.Item>
        <Form.Item name='memo' label='备注'>
          <Input placeholder='非必须填写'/>
        </Form.Item>
        <Form.Item name='fileList' label='上传考评表照片' rules={[{ required: true, message: '请上传评分表照片' }]}>
          <ImageUploader style={{margin: 10}}
                         value={fileList}
                         onChange={(v) => setFileList(v)}
                         upload={(files: File) => dispatch.applyScore.upload(files)}
                         showUpload={true}
                         multiple={false}
                         maxCount={1}
            // beforeUpload={beforeUpload}
            // onCountExceed={exceed => {
            //   Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`)
            // }}
          />
        </Form.Item>
      </Form>
    </>
  )
}

const StatusErrFrame: React.FC<any> = (props) => {

  const onClose = () => {
    WeixinJSBridge.call('closeWindow');
  }

  return (
    <div className={'success'}>
      <div className={'result'}>
        <Result
          status='error'
          title='错误'
          description={props.msg}
        />
      </div>
      <div className={'btn'}>
        <Button style={{width: '100%'}} block color='primary' size='large' onClick={onClose}>关闭</Button>
      </div>
    </div>
  );
}

const StatusSuccessFrame: React.FC<any> = (props) => {

  const onClose = () => {
    WeixinJSBridge.call('closeWindow');
  }

  return (
    <div className={'success'}>
      <div className={'result'}>
        <Result
          status='success'
          title='成功'
          description='考评成绩录入成功'
        />
      </div>
      <div className={'btn'}>
        <Button style={{width: '100%'}} block color='primary' size='large' onClick={onClose}>关闭</Button>
      </div>
    </div>
  );
}

export default ApplyScore;